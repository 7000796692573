.button {
  color: #fff;
  border-radius: 8px;
  margin-right: 15px;
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  display: inline-block;
}

.button.transparent {
  background-color: #0000;
  border: 1px solid #fff;
}

.button.red {
  background-color: #ef5356;
  border: 1px solid #ef5356;
}

.button.dark {
  color: #536ced;
  background-color: #0000;
  border: 1px solid #536ced;
}

.button.dark.bracket:after {
  filter: invert();
}

.button.bracket:after {
  content: " ";
  contain: content;
  background-image: url("../images/bracket.e260c2a1.svg");
  width: 5px;
  height: 8px;
  margin-left: 14px;
  display: inline-block;
  position: relative;
  bottom: 4px;
}
/*# sourceMappingURL=button.d252e2c6.css.map */
